import firebase from "firebase/app";

import getDistributionChannelsQuery from "astrid-firestore/src/api/distribution/getDistributionChannelsQuery";
import { canDeliverArticlesToChannels } from "astrid-firestore/src/api/distribution/utils/deliverArticlesToChannels";
import getArticlePresetChannels from "astrid-firestore/src/api/distribution/utils/getArticlePresetChannels";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";
import useLoadingValues from "astrid-firestore/src/hooks/useLoadingValues";

export default function useArticlesChannels({ articles }) {
	const [[publisher, allChannels], loading, error] = useLoadingValues(
		useDocumentData(articles[0].publisher.ref),
		useCollectionData(firebase.firestore().collection("distributionChannels").where("deleted", "==", false)),
	);

	const articleDistributionChannels = articles
		.map((article) => {
			return getArticlePresetChannels({ article, publisher, allChannels });
		})
		.flat();

	const uniqueChannelIds = [...new Set(articleDistributionChannels?.map((channel) => channel?.id))];

	const presetChannels = allChannels?.filter((channel) => uniqueChannelIds.includes(channel.id));

	const channels = presetChannels?.filter((channel) =>
		canDeliverArticlesToChannels({
			articles,
			channel,
		}),
	);

	return [channels, loading, error];
}
