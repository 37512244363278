import React from "react";
import { Outlet, useOutletContext, useParams } from "react-router-dom";

import { db } from "astrid-firebase";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import ArtifactView from "../../views/ArtifactView/ArtifactView";

export default function Artifact() {
	const params = useParams();
	const { work, article, production } = useOutletContext();

	const [artifact, loading, error] = useDocumentData(db.collection("artifacts").doc(params.artifact));
	const [publisher, loadingPublisher, errorPublisher] = useDocumentData(article?.publisher?.ref);

	return (
		<LoadingContext
			data={artifact}
			loading={loading || loadingPublisher}
			error={error || errorPublisher}
			showDeleted
		>
			<ArtifactView work={work} article={article} artifact={artifact} production={production}>
				<Outlet context={{ work, article, artifact, production, publisher }} />
			</ArtifactView>
		</LoadingContext>
	);
}
