import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { custom, enumValues, nullable, string, timestamp } from "../../../types/_types";

import { ArticleRef } from "../../articles/types/ArticleRef";
import { OrganizationRef } from "../../organizations/types/OrganizationRef";
import { Pipeline } from "../../pipelines/types/Pipeline";

import distributionStatuses from "../constants/distributionStatuses";

import { DistributionChannelRef } from "./DistributionChannelRef";

export const distributionStatus = enumValues(distributionStatuses).default(distributionStatuses.QUEUED);

export const distributionDelivery = custom((value) => value.metadata || value.artifact, {
	params: { i18n: "errors.distribution.delivery.empty" },
});

export const Distribution = FirestoreDocument.extend({
	article: ArticleRef,
	channel: DistributionChannelRef,
	delivery: distributionDelivery,
	error: nullable(string()),
	pipeline: nullable(Pipeline),
	publisher: OrganizationRef,
	startedAt: nullable(timestamp()),
	status: distributionStatus,
	scheduledAt: nullable(timestamp()),
	stoppedAt: nullable(timestamp()),
	jobId: nullable(string()),
}).merge(FirestoreLifecycle);
