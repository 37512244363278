import mapDocument from "../utils/mapDocument";

export default function runTransaction(firebase, refsOrDocuments = [], callback, transaction) {
	const runCallback = async (transaction) => {
		const getAllDocuments = (refsOrDocuments) => {
			return Promise.all(
				refsOrDocuments.map((refOrDocument) => {
					if (Array.isArray(refOrDocument)) {
						return getAllDocuments(refOrDocument);
					}

					if (refOrDocument) {
						const ref = refOrDocument?.ref || refOrDocument;
						if (transaction.cache[ref.path]) {
							return Promise.resolve(transaction.cache[ref.path]);
						}

						return transaction
							.get(ref)
							.then(mapDocument)
							.then((doc) => {
								transaction.cache[doc.ref.path] = doc;
								return doc;
							});
					}
				}),
			);
		};

		const documents = await getAllDocuments(refsOrDocuments);

		return callback(transaction, documents);
	};

	if (transaction) {
		return runCallback(transaction);
	}

	return firebase.firestore().runTransaction((transaction) => {
		transaction.cache = {};
		return runCallback(transaction);
	});
}
