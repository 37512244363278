import { useTranslation } from "react-i18next";

import AdminCard from "../../../admin/components/AdminCard/AdminCard";
import useHasRight from "../../../authorization/hooks/useHasRight";

import CreateDistributionArgsButton from "../../components/CreateDistributionCredentials/CreateDistributionArgsButton";
import CreateDistributionPresetButton from "../../components/CreateDistributionPreset/CreateDistributionPresetButton";
import DistributionArgsDataTable from "../../components/DistributionCredentialsDataTable/DistributionArgsDataTable";
import DistributionPresetsDataTable from "../../components/DistributionPresetsDataTable/DistributionPresetsDataTable";

export default function DistributionSettingsView({ publisher }) {
	const { t } = useTranslation();
	const hasRight = useHasRight();

	return (
		<>
			<AdminCard header={t("presets", "Presets")}>
				<DistributionPresetsDataTable publisher={publisher} />

				{hasRight("distributions.addPresets") && <CreateDistributionPresetButton publisher={publisher} />}
			</AdminCard>

			{hasRight("distributions.addPresets") && (
				<AdminCard header={t("overriddenDistributionArgs", "Overridden distribution args")}>
					<DistributionArgsDataTable publisher={publisher} />
					<CreateDistributionArgsButton publisher={publisher} />
				</AdminCard>
			)}
		</>
	);
}
