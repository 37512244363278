import articleDeliveryStatuses from "../../src/api/articles/constants/articleDeliveryStatuses";
import getDeliveryStatusByPriority from "../../src/api/articles/getDeliveryStatusByPriority";

function mapStatusesByDeliveryType({ channels, deliveryType }) {
	return Object.values(channels || {}).map((channel) => {
		return channel.delivery?.[deliveryType]?.status;
	});
}

export default function createArticleDelivery({ channels }) {
	const artifactStatuses = mapStatusesByDeliveryType({ channels, deliveryType: "artifact" });
	const metadataStatuses = mapStatusesByDeliveryType({ channels, deliveryType: "metadata" });

	const artifactStatus =
		getDeliveryStatusByPriority(artifactStatuses) || articleDeliveryStatuses.DELIVERY_NOT_STARTED;
	const metadataStatus =
		getDeliveryStatusByPriority(metadataStatuses) || articleDeliveryStatuses.DELIVERY_NOT_STARTED;

	return {
		artifact: {
			status: artifactStatus,
		},
		metadata: {
			status: metadataStatus,
		},
	};
}
