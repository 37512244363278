import deepmerge from "deepmerge";
import isPlainObject from "is-plain-object";

import createDocumentData from "../../utils/createDocumentData";
import { fromDotObject } from "../../utils/dotObject";
import getCollectionData from "../../utils/getCollectionData";
import getCollectionDataById from "../../utils/getCollectionDataById";
import runTransaction from "../../utils/runTransaction";

import distributionStatuses from "../distribution/constants/distributionStatuses";
import { channelSupportsArticle } from "../distribution/utils/deliverArticlesToChannels";
import { Imprint } from "../imprints/types/Imprint";
import { Serie } from "../series/types/Serie";

import articleDeliveryStatuses from "./constants/articleDeliveryStatuses";
import articleStatuses from "./constants/articleStatuses";
import updateArticleChannelDeliveryNeeded from "./data/updateArticleChannelDeliveryNeeded";
import { updateArticleDeliveryStatus } from "./data/updateArticleDeliveryStatus";
import updateArticleStatusToDraft from "./data/updateArticleStatusToDraft";
import { Article } from "./types/Article";
import { ArticleVersion } from "./types/ArticleVersion";
import validateArticleISBN from "./validateArticleISBN";

export default async function updateArticle(firebase, data, { transaction, saveVersion = false } = {}) {
	data = fromDotObject(data);
	if (data.isbn) {
		await validateArticleISBN(data);
	}

	return runTransaction(
		firebase,
		[data.ref, data.serie?.ref, data.imprint?.ref, data.artifact?.ref],
		async (transaction, [article, serie, imprint, artifact]) => {
			// Update the duration for default artifact
			if (artifact && !data.duration) {
				data.duration = artifact.duration;
			}

			// If the imprint does not exist, create it
			if (data.imprint && !imprint?.exists) {
				transaction.set(imprint.ref, Imprint.parse({ ...data.imprint, publisher: article.publisher }));
			}

			// Add the article to a production
			if (data.production) {
				transaction.update(data.production.ref, {
					articleIds: firebase.firestore.FieldValue.arrayUnion(article.id),
				});
			}

			// Update serie data if serie is changed or season is changed
			if (data.serie && (data.serie.id !== article.serie?.id || data.season?.id !== article.season?.id)) {
				const articleIds = firebase.firestore.FieldValue.arrayUnion(article.id);
				const seasons = serie.seasons?.find((s) => s.id === data.season?.id)
					? serie.seasons
					: [...(serie.seasons || []), data.season].filter(Boolean);

				if (serie?.exists) {
					transaction.update(serie.ref, {
						...Serie.partial().parse({ seasons }),
						articleIds,
					});
				} else {
					transaction.set(serie.ref, {
						...Serie.parse({ ...data.serie, seasons, publisher: article.publisher }),
						articleIds,
					});
				}
			}

			// Update serie on article. Remove article from previous selected serie
			if (article?.serie?.id && article.serie?.id !== serie?.id) {
				transaction.update(article.serie.ref, {
					articleIds: firebase.firestore.FieldValue.arrayRemove(article.id),
				});
			}

			// Save the updated article in the version history
			if (saveVersion) {
				const version = ArticleVersion.parse(
					createDocumentData(firebase, `articles/${article.id}/versions`, {
						before: article,
						after: {
							...article,
							...data,
						},
					}),
				);

				transaction.set(version.ref, version);
			}

			if (data.status === articleStatuses.DRAFT) {
				if (
					["metadata", "artifact"].some(
						(deliveryType) =>
							article.delivery?.[deliveryType]?.status === articleDeliveryStatuses.DELIVERY_IN_PROGRESS,
					)
				) {
					throw new Error("Cannot set article to draft while delivery is in progress");
				}
				data = fromDotObject({ ...data, ...updateArticleStatusToDraft({ article }) });
				const previouslyScheduledDistributions = await getCollectionData(
					firebase
						.firestore()
						.collection("distributions")
						.where("article.ref", "==", article.ref)
						.where("status", "==", distributionStatuses.SCHEDULED),
				);

				for (const previous of previouslyScheduledDistributions) {
					transaction.delete(previous.ref);
				}
			} else if (article.status === articleStatuses.READY || data.status === articleStatuses.READY) {
				// Updated delivery needed status if metadata has changed (per article channel)
				data = fromDotObject({
					...data,
					...updateArticleChannelDeliveryNeeded(article, data),
				});
			}

			if (data.channels || data.channelIds) {
				const mergedArticle = deepmerge(article, data, {
					isMergeableObject: (value) => {
						// igonore firestore refs
						return isPlainObject(value) && !value?.firestore;
					},
				});

				// update global delivery status for article (based on channel delivery status)
				data = fromDotObject({
					...data,
					...updateArticleDeliveryStatus({ article: mergedArticle }),
				});
			}

			const validatedArticle = Article.partial().parse(data);

			transaction.set(article.ref, validatedArticle, { merge: true });

			return validatedArticle;
		},
		transaction,
	);
}
