import { useTranslation } from "react-i18next";

import articleStatuses from "astrid-firestore/src/api/articles/constants/articleStatuses";

import Flex from "../../../../components/Flex/Flex";

import useArticleDeliveryStatus from "../../hooks/useArticleDeliveryStatus";

import ArticleDeliveryStatusLabel from "./ArticleDeliveryStatusLabel";

export default function ArticleDeliveryStatus({ article, channel, onClick }) {
	const { t } = useTranslation();

	const artifactStatus = useArticleDeliveryStatus("artifact", { article, channel });
	const metadataStatus = useArticleDeliveryStatus("metadata", { article, channel });

	if (article.status === articleStatuses.DRAFT) {
		return <ArticleDeliveryStatusLabel text={t("draft", "Draft")} />;
	}

	if (channel && !article.channelIds?.includes(channel?.id)) {
		return (
			<Flex justifyContent="center">
				<ArticleDeliveryStatusLabel text={t("disabled", "Disabled")} />
			</Flex>
		);
	}

	return (
		<Flex gap={10} style={{ flex: 0 }}>
			<ArticleDeliveryStatusLabel
				text={t("meta", "Meta")}
				onClick={onClick && ((e) => onClick(e, { label: "meta" }))}
				{...metadataStatus}
			/>
			<ArticleDeliveryStatusLabel
				text={t("files", "Files")}
				onClick={onClick && ((e) => onClick(e, { label: "artifact" }))}
				{...artifactStatus}
			/>
		</Flex>
	);
}
