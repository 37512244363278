import { Grid } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import useDocTitle from "../../../ui/hooks/useDocTitle";

import HomeLayoutLeftColumn from "./components/HomeLayoutLeftColumn";
import HomeLayoutRightColumn from "./components/HomeLayoutRightColumn";
import HomeLayoutWidget from "./components/HomeLayoutWidget";

export default function HomeLayout({ children }) {
	const { t } = useTranslation();
	useDocTitle(t("astrid", "Astrid"));

	return <Grid stackable>{children}</Grid>;
}

HomeLayout.LeftColumn = HomeLayoutLeftColumn;
HomeLayout.RightColumn = HomeLayoutRightColumn;
HomeLayout.Widget = HomeLayoutWidget;
