import { fromDotObject } from "../../../utils/dotObject";
import getCollectionData from "../../../utils/getCollectionData";
import runTransaction from "../../../utils/runTransaction";

import distributionStatuses from "../../distribution/constants/distributionStatuses";
import { channelSupportsArticle } from "../../distribution/utils/deliverArticlesToChannels";

import articleDeliveryStatuses from "../constants/articleDeliveryStatuses";
import updateArticle from "../updateArticle";

export default async function updateArticleChannelIds(firebase, { article, channel }) {
	await runTransaction(firebase, [article.ref, channel.ref], async (transaction, [article, channel]) => {
		const disabledChannel = article?.channelIds.includes(channel.id);

		if (disabledChannel) {
			const previouslyScheduledDistributions = await getCollectionData(
				firebase
					.firestore()
					.collection("distributions")
					.where("article.ref", "==", article.ref)
					.where("channel.id", "==", channel.id)
					.where("status", "==", distributionStatuses.SCHEDULED),
			);

			for (const previous of previouslyScheduledDistributions) {
				transaction.delete(previous.ref);
			}

			const articleChannelData = ["metadata", "artifact"].reduce((acc, deliveryType) => {
				if (
					article.channels?.[channel.id]?.delivery?.[deliveryType]?.status ===
					articleDeliveryStatuses.DELIVERY_COMPLETED
				) {
					return acc;
				}

				return {
					...acc,
					[`channels.${channel.id}.delivery.${deliveryType}.status`]: channelSupportsArticle({
						article,
						channel,
						deliveryType,
					})
						? articleDeliveryStatuses.DELIVERY_NEEDED
						: articleDeliveryStatuses.DELIVERY_UNSUPPORTED,
				};
			}, {});

			await updateArticle(
				firebase,
				{
					ref: article.ref,
					channelIds: article.channelIds.filter((channelId) => channelId !== channel.id),
					...fromDotObject(articleChannelData || {}),
				},
				{ transaction },
			);
		} else {
			await updateArticle(
				firebase,
				{ ref: article.ref, channelIds: [...article.channelIds, channel.id] },
				{ transaction },
			);
		}
	});
}
