import createDocumentData from "../../utils/createDocumentData";

import distributionStatuses from "./constants/distributionStatuses";
import { Distribution } from "./types/Distribution";

function getScheduledDate(date, daysToSubtract = 0) {
	return date && new Date(date.getTime() - daysToSubtract * 24 * 60 * 60 * 1000);
}

function normalizeDate(date) {
	return date && new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

function isFutureDate(date, now) {
	return normalizeDate(date) > normalizeDate(now);
}

function isTodayOrPastDate(date, now) {
	return normalizeDate(date) <= normalizeDate(now);
}

function isSameDay(date1, date2) {
	return normalizeDate(date1)?.getTime() === normalizeDate(date2)?.getTime();
}

function updateDeliveryBasedOnRelease({ delivery, scheduledReleaseDate, today }) {
	if (delivery.metadata) {
		if (isFutureDate(scheduledReleaseDate, today)) {
			// this makes it possible to clear all previously scheduled dists if the users does the same thing over and over.
			return {
				...delivery,
				artifact: true,
			};
		}
	}
	return delivery;
}

function schedule(firebase, { publisher, article, channel, delivery, scheduledAt }) {
	return Distribution.parse(
		createDocumentData(firebase, "distributions", {
			publisher,
			article,
			channel,
			delivery,
			status: distributionStatuses.SCHEDULED,
			scheduledAt: firebase.firestore.Timestamp.fromDate(scheduledAt),
		}),
	);
}

function queue(firebase, { publisher, article, channel, delivery }) {
	return Distribution.parse(
		createDocumentData(firebase, "distributions", {
			publisher,
			article,
			channel,
			delivery,
		}),
	);
}

function takeDown(firebase, { scheduledTakedownDate, supportsTakedownDate, data }) {
	const { delivery, today } = data;
	if (delivery.metadata) {
		const deliveryWithoutArtifact = { ...delivery, artifact: false };
		if (!supportsTakedownDate && isFutureDate(scheduledTakedownDate, today)) {
			return schedule(firebase, {
				...data,
				delivery: deliveryWithoutArtifact,
				scheduledAt: scheduledTakedownDate,
			});
		}
		return queue(firebase, { ...data, delivery: deliveryWithoutArtifact });
	}
}

function announce(firebase, { announcementDate, supportsAnnouncementDate, data }) {
	const { delivery } = data;
	if (delivery.metadata) {
		const deliveryWithoutArtifact = { ...delivery, artifact: false };
		if (!supportsAnnouncementDate && isFutureDate(announcementDate, data.today)) {
			return schedule(firebase, { ...data, delivery: deliveryWithoutArtifact, scheduledAt: announcementDate });
		}
		return queue(firebase, { ...data, delivery: deliveryWithoutArtifact });
	}
}

function release(firebase, { announcementDate, scheduledReleaseDate, releaseDateGracePeriod, data }) {
	const { today, delivery } = data;
	let releases = [];

	const scheduleArtifact = delivery.artifact && isFutureDate(scheduledReleaseDate, today);
	const queueArtifact = delivery.artifact && isTodayOrPastDate(scheduledReleaseDate, today);

	const scheduleMetadata = delivery.metadata && isFutureDate(scheduledReleaseDate, today);

	const queueMetadata = delivery.metadata && (!announcementDate || isTodayOrPastDate(scheduledReleaseDate, today));

	if (queueMetadata || queueArtifact) {
		releases.push(
			queue(firebase, {
				...data,
				delivery: {
					metadata: queueMetadata || false,
					artifact: queueArtifact || false,
				},
			}),
		);
	}

	if (scheduleMetadata || scheduleArtifact) {
		releases.push(
			schedule(firebase, {
				...data,
				delivery: {
					metadata: scheduleMetadata || false,
					artifact: scheduleArtifact || false,
				},
				scheduledAt: getScheduledDate(scheduledReleaseDate, releaseDateGracePeriod),
			}),
		);
	}

	return releases;
}

export default function queueOrScheduleDistributions(
	firebase,
	{ publisher, article, channel, delivery, today = new Date() },
) {
	const channelData = article.channels[channel.id];
	const { supportsAnnouncementDate, supportsTakedownDate, releaseDateGracePeriod, takedownDateGracePeriod } = channel;

	const announcementDate = channelData.announcementDate && new Date(channelData.announcementDate);
	const releaseDate = channelData.releaseDate && new Date(channelData.releaseDate);
	const takedownDate = channelData.takedownDate && new Date(channelData.takedownDate);

	const scheduledReleaseDate = getScheduledDate(releaseDate, releaseDateGracePeriod);
	const scheduledTakedownDate = getScheduledDate(takedownDate, takedownDateGracePeriod);

	const data = {
		publisher,
		article,
		channel,
		delivery: updateDeliveryBasedOnRelease({ channel, delivery, scheduledReleaseDate, today }),
		today,
	};

	const distributions = [];

	if (!announcementDate && !releaseDate && !takedownDate) {
		throw new Error(`Could not create deliveries. ${channel.name} is missing release date`);
	}

	if (announcementDate && (!releaseDate || !isTodayOrPastDate(scheduledReleaseDate, today))) {
		distributions.push(announce(firebase, { announcementDate, supportsAnnouncementDate, data }));
	}

	if (releaseDate) {
		distributions.push(
			release(firebase, {
				announcementDate,
				scheduledReleaseDate,
				data,
			}),
		);
	}

	if (
		(takedownDate &&
			!supportsTakedownDate &&
			!isTodayOrPastDate(scheduledTakedownDate, today) &&
			!isSameDay(releaseDate, takedownDate)) ||
		(takedownDate && !releaseDate && !announcementDate)
	) {
		distributions.push(
			takeDown(firebase, {
				scheduledTakedownDate,
				supportsTakedownDate,
				data,
			}),
		);
	}

	return distributions.flat().filter(Boolean);
}
