import getDeliveryStatusByPriority from "../getDeliveryStatusByPriority";

export function updateArticleDeliveryStatus({ article }) {
	return ["metadata", "artifact"].reduce((acc, deliveryType) => {
		const channelDeliveryStatuses = Object.entries(article.channels || {})
			.filter(([channelId]) => article.channelIds?.includes(channelId))
			.map(([_, { delivery }]) => delivery?.[deliveryType]?.status);

		const channelStatusByPriority = getDeliveryStatusByPriority(channelDeliveryStatuses);
		return {
			...acc,
			[`delivery.${deliveryType}.status`]: channelStatusByPriority,
		};
	}, {});
}
