import firebase from "firebase";

import { useMemo } from "react";

import getAutoDeliverySettings from "astrid-firestore/src/api/distribution/getAutoDeliverySettings";
import queueOrScheduleDistributions from "astrid-firestore/src/api/distribution/queueOrScheduleDistributions";

export default function useAutoDeliveries({ article, publisher, channels }) {
	return useMemo(() => {
		try {
			if (!article || !publisher || !channels) {
				return [[], false];
			}

			const deliveries = channels.filter((channel) => {
				const delivery = getAutoDeliverySettings({ article, channel });
				const distributions = queueOrScheduleDistributions(firebase, {
					publisher,
					article,
					channel,
					delivery,
				});
				return distributions.length > 0;
			});

			return [deliveries, false];
		} catch (err) {
			console.error("Auto deliveries error:", err);
			return [[], err];
		}
	}, [article, publisher, channels]);
}
