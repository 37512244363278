import firebase from "firebase/app";

import React from "react";
import { useTranslation } from "react-i18next";

import applyDistributionPresets from "astrid-firestore/src/api/articles/applyDistributionPresets";
import articleStatuses from "astrid-firestore/src/api/articles/constants/articleStatuses";
import { ArticleChannelMetaData } from "astrid-firestore/src/api/articles/types/ArticleChannel";
import updateArticle from "astrid-firestore/src/api/articles/updateArticle";

import { formatDate } from "../../../../helpers/date";

import AdminForm from "../../../admin/components/AdminForm/AdminForm";
import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

export default function ArticleDistributionDraftView({ article }) {
	const { t } = useTranslation();

	const form = useSimpleForm({
		schema: ArticleChannelMetaData.refine(
			({ releaseDate }) => {
				return releaseDate;
			},
			{
				message: "Release date is mandatory",
				path: ["releaseDate"],
			},
		).refine(
			({ announcementDate, releaseDate }) => {
				return !announcementDate || !releaseDate || announcementDate < releaseDate;
			},
			{
				message: "Must be after announcement date",
				path: ["releaseDate"],
			},
		),
		values: {
			ref: article.ref,
			announcementDate: null,
			releaseDate: null,
		},
		onSubmit: async ({ ref, announcementDate, releaseDate }) => {
			const presets = await applyDistributionPresets(firebase, article);

			const channels = Object.entries(presets.channels).reduce((acc, [channelId, channel]) => {
				return {
					...acc,
					[channelId]: {
						...channel,
						announcementDate: announcementDate || null,
						releaseDate: releaseDate || null,
					},
				};
			}, {});
			return updateArticle(firebase, {
				ref,
				channels,
				channelIds: presets.channelIds,
				status: articleStatuses.READY,
			});
		},
	});

	return (
		<>
			<AdminForm submitText={t("readyForDistribution", "Ready for distribution")} submitFloated="right" {...form}>
				<Form.Group widths="equal">
					<Form.DateInput
						name="announcementDate"
						label={t("announcementDate", "Announcement date")}
						formatDate={formatDate}
						rules={{ valueAsDate: false }}
						shouldUnregister={false}
					/>

					<Form.DateInput
						name="releaseDate"
						label={t("releaseDate", "Release date")}
						formatDate={formatDate}
						rules={{ valueAsDate: false }}
						shouldUnregister={false}
					/>
				</Form.Group>
			</AdminForm>
		</>
	);
}
