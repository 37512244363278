import { useOutletContext } from "react-router-dom";

import articleStatuses from "astrid-firestore/src/api/articles/constants/articleStatuses";

import ArticleDistributionDraftView from "../views/ArticleView/ArticleDistributionDraftView";
import ArticleDistributionView from "../views/ArticleView/ArticleDistributionView";

export default function ArticleDistribution() {
	const { publisher, article } = useOutletContext();

	if (article.status === articleStatuses.DRAFT) {
		return <ArticleDistributionDraftView publisher={publisher} article={article} />;
	}

	return <ArticleDistributionView publisher={publisher} article={article} />;
}
