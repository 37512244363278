import { Header } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import ArticleDistributionChannels from "./components/ArticleDistributionChannels";
import SuperAdminArticleDistribution from "./components/SuperAdminArticleDistribution";

export default function ArticleDistributionView({ article, publisher }) {
	const { t } = useTranslation();

	return (
		<>
			<Header as="h3" content={t("distribution", "Distribution")} />
			<ArticleDistributionChannels article={article} publisher={publisher} />
			<SuperAdminArticleDistribution article={article} />
		</>
	);
}
