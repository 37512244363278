import firebase from "firebase/app";

import { Button } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import deleteDistribution from "astrid-firestore/src/api/distribution/deleteDistribution";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

import FlexRow from "../../../../components/Flex/FlexRow";
import ConfirmationModalTrigger from "../../../ui/components/ConfirmationModal/ConfirmationModalTrigger";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import AdvancedDistributeArticlesButton from "../../../articles/components/AdvancedDistributeArticles/AdvancedDistributeArticlesButton";
import Table from "../../../tables/components/Table";

import useDistributionDropdownMenuOptions from "../../hooks/useDistributionDropdownMenuOptions";
import useDistributionsTableColumns from "../../hooks/useDistributionsTableColumns";

export default function ArticleDistributionsTable({ article }) {
	const { t } = useTranslation();
	const db = firebase.firestore();
	const navigate = useNavigate();

	const columns = useDistributionsTableColumns({ showPublisher: false });
	const dropdownMenuOptions = useDistributionDropdownMenuOptions({ article });

	const onClick = ({ id }) => {
		navigate(`../distributions/${id}`);
	};

	const [distributions, loading, error] = useCollectionData(
		db.collection("distributions").where("article.ref", "==", article.ref), //.where("deleted", "==", false),
	);

	const onClearAll = async () => {
		for (const distribution of distributions) {
			await deleteDistribution(firebase, { article, distribution });
		}
	};

	return (
		<>
			<LoadingContext data={distributions} loading={loading} error={error}>
				<Table
					data={distributions?.sort((a, b) => (a.channel?.name ?? "").localeCompare(b.channel?.name ?? ""))}
					columns={columns}
					onClick={onClick}
					getDropdownMenuOptions={dropdownMenuOptions}
				/>
			</LoadingContext>
			<FlexRow style={{ gap: 10, marginBottom: 10 }}>
				<ConfirmationModalTrigger
					text={t("sureDeleteAllDistributions", "Are you sure you wish to delete all distributions?")}
					onConfirm={onClearAll}
					trigger={<Button color="red" content="Clear all" onClick={onClearAll} />}
				/>
				<AdvancedDistributeArticlesButton articles={[article]} text={t("distribute", "Distribute")} primary />
			</FlexRow>
		</>
	);
}
