import React from "react";
import { useTranslation } from "react-i18next";

import { useDocumentData } from "astrid-firestore/src/hooks/index";

import DistributionCountriesCheckboxTable from "../../../../distribution/components/DistributionCountriesCheckboxTable/DistributionCountriesCheckboxTable";
import DistributionPriceForm from "../../../../distribution/components/DistributionPrice/DistributionPriceForm";
import Form from "../../../../forms/components/Form/Form";
import LoadingContext from "../../../../ui/components/LoadingContext/LoadingContext";

export default function ArticleDistributionTargetDataForm({ name, channel, publisher }) {
	const { t } = useTranslation();

	const key = name ? `${name}.` : "";

	const [fullChannelDoc, loading] = useDocumentData(!channel.targetData && channel.ref) || [channel, false];

	return (
		<LoadingContext data={fullChannelDoc} loading={loading}>
			{fullChannelDoc?.subChannels?.length > 0 && (
				<Form.DocumentSelect
					multiple
					name={`${key}subChannels`}
					label={t("subChannels", "Sub channels")}
					options={fullChannelDoc?.subChannels?.map((subChannel) => ({
						key: subChannel.id,
						value: subChannel.id,
						text: subChannel.name,
						data: subChannel,
					}))}
				/>
			)}

			<DistributionPriceForm name={`${key}prices`} publisher={publisher} />

			<DistributionCountriesCheckboxTable name={`${key}countries`} />
		</LoadingContext>
	);
}
