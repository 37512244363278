import { array, date, nullable, object, string } from "../../../types/_types";

import { ArticleChannelDelivery } from "./ArticleChannelDelivery";
import { ArticlePrice } from "./ArticlePrice";

export const ArticleChannelMetaData = object({
	announcementDate: nullable(date()),
	releaseDate: nullable(date()),
	takedownDate: nullable(date()),
	countries: nullable(array(string())),
	prices: array(ArticlePrice),
	subChannels: array(object({ id: string(), name: string() })),
});

export const ArticleChannel = ArticleChannelMetaData.extend({
	delivery: ArticleChannelDelivery.partial().default({}),
});
