import articleDeliveryStatuses from "../../articles/constants/articleDeliveryStatuses";

import distributionStatuses from "./distributionStatuses";

const distributionStatusToArticleStatus = {
	[distributionStatuses.PENDING]: articleDeliveryStatuses.DELIVERY_PENDING,
	[distributionStatuses.SCHEDULED]: articleDeliveryStatuses.DELIVERY_PENDING,
	[distributionStatuses.INITIALIZED]: articleDeliveryStatuses.DELIVERY_IN_PROGRESS,
	[distributionStatuses.RUNNING]: articleDeliveryStatuses.DELIVERY_IN_PROGRESS,
	[distributionStatuses.COMPLETED]: articleDeliveryStatuses.DELIVERY_COMPLETED,
	[distributionStatuses.CANCELLED]: articleDeliveryStatuses.DELIVERY_FAILED,
	[distributionStatuses.FAILED]: articleDeliveryStatuses.DELIVERY_FAILED,
};

export default distributionStatusToArticleStatus;
