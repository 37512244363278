import { useState } from "react";

import Table from "../../../../tables/components/Table";
import LoadingContext from "../../../../ui/components/LoadingContext/LoadingContext";

import useArticleDistributionChannelDropdownMenuOptions from "../../../components/ArticleDistributionChannels/hooks/useArticleDistributionChannelDropdownMenuOptions";
import useArticleDistributionChannelsTableColumns from "../../../components/ArticleDistributionChannels/hooks/useArticleDistributionChannelsTableColumns";
import DeliverArticleButton from "../../../components/DistributeArticles/DeliverArticleButton";
import useArticlePresetChannels from "../../../hooks/useArticlePresetChannels";

import ArticleDistributionChannelForm from "./ArticleDistributionChannelForm";

export default function ArticleDistributionChannels({ publisher, article }) {
	const [selectedChannel, setSelectedChannel] = useState(null);
	const [channels, loading, error] = useArticlePresetChannels({ article });

	const columns = useArticleDistributionChannelsTableColumns({ article, setSelectedChannel });
	const dropdownMenuOptions = useArticleDistributionChannelDropdownMenuOptions({ article, setSelectedChannel });

	const getRowDisabled = ({ id }) => !article.channelIds?.includes(id);

	const sortedChannels = [...channels].sort((a, b) => {
		const aDisabled = getRowDisabled(a);
		const bDisabled = getRowDisabled(b);
		return aDisabled - bDisabled;
	});

	return (
		<LoadingContext loading={loading} error={error}>
			<Table
				data={sortedChannels}
				columns={columns}
				onClick={(channel) => {
					setSelectedChannel(channel);
				}}
				getDropdownMenuOptions={dropdownMenuOptions}
				getRowDisabled={getRowDisabled}
			>
				<DeliverArticleButton article={article} publisher={publisher} />
			</Table>
			{selectedChannel && (
				<ArticleDistributionChannelForm
					channel={selectedChannel}
					article={article}
					publisher={publisher}
					onClose={() => setSelectedChannel(null)}
				/>
			)}
		</LoadingContext>
	);
}
