import useArticlesChannels from "../../hooks/useArticlesChannels";

import AdvancedDistributeArticlesForm from "./AdvancedDistributeArticlesForm";

export default function AdvancedDistributeArticles({ articles, clickedChannel, onClose }) {
	const [channels, loading] = useArticlesChannels({ articles });

	if (loading) {
		return null;
	}

	return (
		<AdvancedDistributeArticlesForm
			articles={articles}
			channels={channels}
			clickedChannel={clickedChannel}
			onClose={onClose}
		/>
	);
}
