import getCollectionData from "../../utils/getCollectionData";

import distributionStatuses from "./constants/distributionStatuses";

export default async function clearPreviouslyScheduledDistributions(
	firebase,
	transaction,
	{ article, newDistributions, channelId },
) {
	const db = firebase.firestore();
	const previouslyScheduledDistributions = await getCollectionData(
		db
			.collection("distributions")
			.where("article.ref", "==", article.ref)
			.where("channel.id", "==", channelId)
			.where("status", "==", distributionStatuses.SCHEDULED),
	);

	if (newDistributions.length) {
		for (const previous of previouslyScheduledDistributions) {
			transaction.delete(previous.ref);
		}
	}
}
