import articleDeliveryStatuses from "./constants/articleDeliveryStatuses";

const articleDeliveryStatusPriority = [
	articleDeliveryStatuses.DELIVERY_IN_PROGRESS,
	articleDeliveryStatuses.DELIVERY_FAILED,
	articleDeliveryStatuses.DELIVERY_NEEDED,
	articleDeliveryStatuses.DELIVERY_PENDING,
	articleDeliveryStatuses.DELIVERY_COMPLETED,
	articleDeliveryStatuses.DELIVERY_NOT_STARTED,
	articleDeliveryStatuses.DELIVERY_UNSUPPORTED,
];

export default function getDeliveryStatusByPriority(statuses) {
	return articleDeliveryStatusPriority.find((status) => statuses.includes(status));
}
