import runTransaction from "../../utils/runTransaction";

import articleStatuses from "../articles/constants/articleStatuses";
import updateArticle from "../articles/updateArticle";
import secondsToDuration from "../articles/utils/secondsToDuration";
import distributeArticles from "../distribution/distributeArticles";
import productionStatuses from "../productions/constants/productionStatuses";

import updateArtifact from "./updateArtifact";

export default async function deliverArtifacts(firebase, { production, articles, artifacts, user }) {
	const article = articles.length === 1 ? articles[0] : null;
	const artifact = artifacts.length === 1 ? artifacts[0] : null;

	// Add all artifacts to the work & article
	await Promise.all(
		artifacts.map((artifact) =>
			updateArtifact(
				firebase,
				{
					ref: artifact.ref,
					work: production.work,
					article,
				},
				[],
			),
		),
	);

	// If there is only one artifact, auto-select the artifact
	if (article && artifact) {
		await updateArticle(firebase, {
			ref: article.ref,
			artifact,
		});
	}

	return runTransaction(firebase, [production.ref, articles], async (transaction, [production, articles]) => {
		const data = {
			status: "done",
			deliveryDateActual: firebase.firestore.FieldValue.serverTimestamp(),
			deliveryDuration: secondsToDuration(artifact.duration, { padHours: 2 }),
		};

		transaction.update(production.ref, data);
		transaction.set(production.ref.collection("events").doc(), {
			user: user.id,
			email: user.email,
			data,
			time: firebase.firestore.FieldValue.serverTimestamp(),
		});

		const readyArticles = articles.filter((article) => article.status === articleStatuses.READY);

		await distributeArticles(firebase, { articles: readyArticles }, { transaction });

		return {
			finalized: production.status !== productionStatuses.DONE,
			deliveryDuration: data.deliveryDuration,
		};
	});
}
