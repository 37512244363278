import { ArticleChannel } from "../types/ArticleChannel";

export default function updateArticleStatusToDraft({ article }) {
	return Object.keys(article.channels || {}).reduce(
		(acc, id) => {
			return {
				...acc,
				[`channels.${id}`]: ArticleChannel.parse({}),
			};
		},
		{ channelIds: [] },
	);
}
