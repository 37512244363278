import { getCollectionData } from "../../helpers/index";
import getDocumentData from "../../utils/getDocumentData";
import runTransaction from "../../utils/runTransaction";

import articleDeliveryStatuses from "../articles/constants/articleDeliveryStatuses";
import updateArticle from "../articles/updateArticle";
import artifactValidationStatuses from "../artifacts/constants/artifactValidationStatuses";

import clearPreviouslyScheduledDistributions from "./clearPreviouslyScheduledDistributions";
import distributionStatuses from "./constants/distributionStatuses";
import getAutoDeliverySettings from "./getAutoDeliverySettings";
import getTriggerDistributionQueueData from "./getTriggerDistributionQueueData";
import queueOrScheduleDistributions from "./queueOrScheduleDistributions";
import { canDeliverArticleToChannel } from "./utils/deliverArticlesToChannels";
import getArticlePresetChannels from "./utils/getArticlePresetChannels";

function deliveryInProgress({ distribution }) {
	return [
		distributionStatuses.QUEUED,
		distributionStatuses.PENDING,
		distributionStatuses.INITIALIZED,
		distributionStatuses.RUNNING,
	].includes(distribution?.status);
}

async function validateDistribution({ article, channel, delivery }) {
	const { metadata: orderedDeliveryOfMetadata, artifact: orderedDeliveryOfArtifact } = delivery;
	const { metadata, artifact } = article?.channels?.[channel.id]?.delivery || {};

	if (!orderedDeliveryOfMetadata && !orderedDeliveryOfArtifact) {
		throw new Error("No delivery was selected!");
	}

	if (orderedDeliveryOfMetadata) {
		if (deliveryInProgress(metadata)) {
			throw new Error(`Delivery of metadata is already in progress for ${channel.name}`);
		}
	}

	if (orderedDeliveryOfArtifact) {
		const theArtifact = article.artifact?.ref && (await article.artifact?.ref?.get());
		if (theArtifact) {
			const artifactStatus = theArtifact?.data?.()?.status;

			if ([artifactValidationStatuses.FAILED, artifactValidationStatuses.INVALID].includes(artifactStatus)) {
				throw new Error("The artifact for this article is not valid for distribution.");
			}

			if (artifactStatus !== artifactValidationStatuses.VALID) {
				throw new Error(
					"The artifact for this article is not ready for distribution. We are currently validating it. Please try again later.",
				);
			}

			if (deliveryInProgress(artifact)) {
				throw new Error(`Delivery of artifact is already in progress for ${channel.name}`);
			}
		}
	}
}

export default async function distributeArticles(firebase, { articles, delivery }, { transaction } = {}) {
	const allChannels = await getCollectionData(
		firebase.firestore().collection("distributionChannels").where("deleted", "==", false),
	);

	return runTransaction(
		firebase,
		[articles],
		async (transaction, [articles]) => {
			let distributionDocs = [];

			for (const article of articles) {
				const publisher = await getDocumentData(article.publisher.ref);
				const presetChannels = getArticlePresetChannels({
					article,
					publisher,
					allChannels,
				});
				const distributionChannels = presetChannels?.filter((channel) =>
					canDeliverArticleToChannel({ article, channel }),
				);

				let articleChannelData = {};
				for (const channel of distributionChannels) {
					const deliveryOrAuto = delivery?.[channel.id] || getAutoDeliverySettings({ article, channel });
					if (Object.values(deliveryOrAuto).some(Boolean)) {
						await validateDistribution({ article, channel, delivery: deliveryOrAuto });

						const distributions = queueOrScheduleDistributions(firebase, {
							publisher,
							article,
							channel,
							delivery: deliveryOrAuto,
						});

						distributionDocs.push(...distributions);

						await clearPreviouslyScheduledDistributions(firebase, transaction, {
							article,
							channelId: channel.id,
							newDistributions: distributions,
						});

						for (const distribution of distributions) {
							transaction.set(distribution.ref, distribution);
						}

						articleChannelData = ["metadata", "artifact"].reduce((acc, deliveryType) => {
							const createdDistributionForDeliveryType = distributions.some(
								(d) => d.delivery[deliveryType],
							);

							if (createdDistributionForDeliveryType) {
								return {
									...acc,
									[`channels.${channel.id}.delivery.${deliveryType}.status`]:
										articleDeliveryStatuses.DELIVERY_PENDING,
								};
							}
							return acc;
						}, articleChannelData);
					}
				}
				if (Object.keys(articleChannelData).length) {
					await updateArticle(firebase, { ref: article.ref, ...articleChannelData }, { transaction });
				}
			}
			// trigger check of distribution queue
			const triggerDistributionQueueData = getTriggerDistributionQueueData(firebase);
			transaction.update(triggerDistributionQueueData.ref, triggerDistributionQueueData);
			return distributionDocs;
		},
		transaction,
	);
}
