import uniqid from "uniqid";

import { useRef } from "react";

import { ArtifactFile, artifactFileTypes } from "astrid-firestore/src/api/artifacts/types/ArtifactFile";
import useBucket from "astrid-hooks/src/useBucket";

import UploadFilesForm from "../../../forms/components/UploadFiles/UploadFilesForm";

import readWavAudioFormat from "./utils/readWavAudioFormat";

export default function ArtifactFilesUploadForm({ auto, bucketName, bucketPath, onSuccess, onClose }) {
	const { putFile } = useBucket(bucketName);

	const uploadedFiles = useRef([]);

	const onUploadFile = async (file, updateProgress) => {
		const id = uniqid();
		const ext = file.name.split(".").pop();

		const [format, { updated, ...metadata }] = await Promise.all([
			readWavAudioFormat(file),
			putFile(`${bucketPath}/${id}.${ext}`, file, ({ bytesTransferred, totalBytes }) => {
				updateProgress(bytesTransferred / totalBytes);
			}),
		]);

		uploadedFiles.current.push(
			ArtifactFile.parse({
				...metadata,
				id,
				name: file.name,
				format,
			}),
		);
	};

	return (
		<UploadFilesForm
			auto={auto}
			accept={artifactFileTypes}
			onClose={onClose}
			onUploadFile={onUploadFile}
			onSuccess={() => onSuccess(uploadedFiles.current)}
		/>
	);
}
