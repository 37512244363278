import articleDeliveryStatuses from "../articles/constants/articleDeliveryStatuses";

import { canDeliverArticleToChannel } from "./utils/deliverArticlesToChannels";

export default function getAutoDeliverySettings({ article, channel }) {
	const metadataRequired = !!channel.disableArtifactDeliveryWithoutMetadata;

	const metadataStatus = article.channels[channel.id]?.delivery?.metadata?.status;
	const artifactStatus = article.channels[channel.id]?.delivery?.artifact?.status;

	const canDeliverMetadata = canDeliverArticleToChannel({
		article,
		channel,
		deliveryType: "metadata",
	});

	const canDeliverArtifact = canDeliverArticleToChannel({
		article,
		channel,
		deliveryType: "artifact",
	});

	const metadataDeliveryNeeded = [
		articleDeliveryStatuses.DELIVERY_NEEDED,
		articleDeliveryStatuses.DELIVERY_FAILED,
	].includes(metadataStatus);
	const artifactDeliveryNeeded = [
		articleDeliveryStatuses.DELIVERY_NEEDED,
		articleDeliveryStatuses.DELIVERY_FAILED,
	].includes(artifactStatus);

	const deliverMetadata =
		(metadataDeliveryNeeded || (metadataRequired && artifactDeliveryNeeded && canDeliverArtifact)) &&
		canDeliverMetadata;

	const deliverArtifact = metadataRequired && !deliverMetadata ? false : artifactDeliveryNeeded && canDeliverArtifact;

	return {
		metadata: deliverMetadata,
		artifact: deliverArtifact,
	};
}
