import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { StorageFile } from "../../../types/StorageFile";
import { array, defaultObject, enumValues, nullable, record, string, timestamp } from "../../../types/_types";

import { ArtifactRef } from "../../artifacts/types/ArtifactRef";
import { OrganizationRef } from "../../organizations/types/OrganizationRef";
import { WorkRef } from "../../works/types/WorkRef";

import articleProductionStatuses from "../constants/articleProductionStatuses";
import articleStatuses from "../constants/articleStatuses";
import articleTypes from "../constants/articleTypes";

import { ArticleChannel } from "./ArticleChannel";
import { articleDeliveryStatus } from "./ArticleChannelDelivery";
import { ArticleMetaData } from "./ArticleMetaData";

export const articleType = enumValues(articleTypes);
export const articleStatus = enumValues(articleStatuses);
export const articleProductionStatus = enumValues(articleProductionStatuses);

export const Article = ArticleMetaData.extend({
	type: articleType,
	status: articleStatus.default(articleStatuses.DRAFT),
	productionStatus: articleProductionStatus.default(articleProductionStatuses.NOT_STARTED),
	artifact: nullable(ArtifactRef),
	work: nullable(WorkRef),
	channels: record(string(20), ArticleChannel.partial()).default({}),
	channelIds: nullable(array(string())),
	delivery: defaultObject({
		artifact: defaultObject({
			status: articleDeliveryStatus,
		}),
		metadata: defaultObject({
			status: articleDeliveryStatus,
		}),
	}),
	deliveryDate: nullable(timestamp()),
	producer: nullable(OrganizationRef),
	publisher: OrganizationRef,
	coverThumb: nullable(StorageFile),
})
	.merge(FirestoreDocument)
	.merge(FirestoreLifecycle);
