import React from "react";
import { useTranslation } from "react-i18next";

import { timestampColumn } from "../../ui/utils/columns";

import PipelineStatusLabel from "../components/DistributionStatus/PipelineStatusLabel";

export default function useDistributionsTableColumns({ showPublisher = false }) {
	const { t } = useTranslation();

	return [
		showPublisher && {
			id: "publisher",
			Header: t("publisher", "Publisher"),
			accessor: ({ publisher }) => publisher.name,
		},
		{
			id: "channel",
			collapsing: true,
			Header: t("channel", "Channel"),
			accessor: ({ channel }) => channel.name,
		},
		{
			id: "delivery",
			Header: t("delivery", "Delivery"),
			Cell: ({ row }) => {
				const { delivery } = row;
				return `${delivery.metadata ? "Meta" : ""} ${delivery.metadata && delivery.artifact ? "& " : ""}${
					delivery.artifact ? "Files" : ""
				}`;
			},
		},
		timestampColumn({ id: "scheduledAt", Header: t("scheduled", "Scheduled"), time: false }),
		timestampColumn({ id: "startedAt", Header: t("startedAt", "Started") }),
		timestampColumn({ id: "stoppedAt", Header: t("stoppedAt", "Stopped") }),
		{
			id: "status",
			Header: t("status", "Status"),
			collapsing: true,
			Cell: ({ row }) => {
				return <PipelineStatusLabel distribution={row} />;
			},
		},
	].filter(Boolean);
}
