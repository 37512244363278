import { StorageFile } from "../../../types/StorageFile";
import { enumValues, nullable, object, timestamp } from "../../../types/_types";

import { DistributionRef } from "../../distribution/types/DistributionRef";

import articleDeliveryStatuses from "../constants/articleDeliveryStatuses";

export const articleDeliveryStatus = enumValues(articleDeliveryStatuses).default(
	articleDeliveryStatuses.DELIVERY_NOT_STARTED,
);
export const ArticleChannelDelivery = object({
	artifact: object({
		status: articleDeliveryStatus,
		sent: nullable(timestamp()),
		distribution: nullable(DistributionRef),
	}).partial(),
	metadata: object({
		status: articleDeliveryStatus,
		sent: nullable(timestamp()),
		distribution: nullable(DistributionRef),
	}).partial(),
	zip: nullable(StorageFile),
});
