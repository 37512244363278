import { List, ListItem } from "semantic-ui-react";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import money from "astrid-helpers/src/money";

import useHasRole from "../../../../authorization/hooks/useHasRole";

import displaySelectedCountries from "../../../views/ArticleView/utils/displaySelectedCountries";

import AdvancedDistributeArticles from "../../AdvancedDistributeArticles/AdvancedDistributeArticles";
import ArticleDeliveryStatus from "../../ArticleDeliveryStatus/ArticleDeliveryStatus";

export default function useArticleDistributionChannelsTableColumns({ article }) {
	const { t } = useTranslation();
	const hasRole = useHasRole();
	const isSuperAdmin = hasRole("owner", "admin");

	return [
		{
			id: "name",
			Header: t("channel", "Channel"),
			Cell: ({ row }) => {
				const { id, name } = row;
				if (article.channels[id]?.subChannels?.length > 0) {
					return `${name} (${article.channels[id].subChannels.length} channels)`;
				}
				return name;
			},
		},
		{
			id: "announcementDate",
			Header: t("announceAt", "Announce"),
			accessor: ({ id, acceptedDeliveryTypes }) => {
				if (!acceptedDeliveryTypes?.length || acceptedDeliveryTypes.includes("metadata")) {
					return article.channels[id]?.announcementDate;
				}
			},
		},
		{
			id: "releaseDate",
			Header: t("release", "Release"),
			accessor: ({ id }) => article.channels[id]?.releaseDate,
		},
		{
			id: "takedownDate",
			Header: t("takedownAt", "Unpublish"),
			accessor: ({ id }) => article.channels[id]?.takedownDate,
		},
		{
			id: "prices",
			Header: t("prices", "Prices"),
			accessor: ({ id }) => {
				return article.channels[id]?.prices;
			},
			Cell: ({ value }) => {
				return (
					<List>
						{value.map((price, index) => {
							return <ListItem key={index}>{money(price.amount, price.currency, 0)}</ListItem>;
						})}
					</List>
				);
			},
		},
		{
			id: "countries",
			Header: t("countries", "Countries"),
			accessor: ({ id }) => displaySelectedCountries(article.channels[id]?.countries),
		},
		{
			id: "status",
			collapsing: true,
			disableSortBy: true,
			Header: t("status", "Status"),
			Cell: ({ row }) => {
				const [clickedChannel, setClickedChannel] = useState(false);
				return (
					<>
						<ArticleDeliveryStatus
							article={article}
							channel={row}
							onClick={(e, { label }) => {
								e.stopPropagation();
								setClickedChannel({ id: row.id, label });
							}}
						/>
						{isSuperAdmin && clickedChannel && (
							<AdvancedDistributeArticles
								articles={[article]}
								clickedChannel={clickedChannel}
								onClose={() => setClickedChannel(false)}
							/>
						)}
					</>
				);
			},
		},
	];
}
