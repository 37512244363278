import articleDeliveryStatuses from "../../src/api/articles/constants/articleDeliveryStatuses";
import articleStatuses from "../../src/api/articles/constants/articleStatuses";
import productionStatuses from "../../src/api/productions/constants/productionStatuses";

export default function createArticleStatus({ api, env }, { production, article }) {
	if (api.isFinalizing || production.status === productionStatuses.DONE) {
		return articleStatuses.READY;
	}

	const hasLegacyDeliveries = Object.keys(article.channels || {}).some((channelId) => {
		const delivery = article?.channels?.[channelId]?.delivery;
		return (
			delivery?.artifact?.status === articleDeliveryStatuses.DELIVERY_COMPLETED ||
			delivery?.metadata?.status === articleDeliveryStatuses.DELIVERY_COMPLETED
		);
	});

	if (hasLegacyDeliveries) {
		return articleStatuses.READY;
	}

	return articleStatuses.DRAFT;
}
