import { useTranslation } from "react-i18next";

import VerticalMenu from "../../../ui/components/VerticalMenu/VerticalMenu";
import useDocTitle from "../../../ui/hooks/useDocTitle";
import PageView from "../../../ui/views/PageView/PageView";

import useOrganizationSettingsMenuOptions from "./hooks/useOrganizationSettingsMenuOptions";

export default function OrganizationSettingsView({ organization, children }) {
	const { t } = useTranslation();
	useDocTitle(t("settings", "Settings"));

	const options = useOrganizationSettingsMenuOptions({ organization });

	return (
		<PageView header={organization.name} sidebar={<VerticalMenu options={options} />}>
			{children}
		</PageView>
	);
}
