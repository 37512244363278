import React from "react";

import CoverImage from "../../../ui/components/CoverImage/CoverImage";
import VerticalMenu from "../../../ui/components/VerticalMenu/VerticalMenu";
import useBreadcrumbs from "../../../ui/hooks/useBreadcrumbs";
import PageView from "../../../ui/views/PageView/PageView";

import AdminPageDropdownMenu from "../../../admin/components/AdminPage/AdminPageDropdownMenu";

import ArticleDeliveryStatus from "../../components/ArticleDeliveryStatus/ArticleDeliveryStatus";
import useArticleTypeTranslations from "../../hooks/useArticleTypeTranslations";

import useArticleDropdownMenuOptions from "./hooks/useArticleDropdownMenuOptions";
import useArticleMenuOptions from "./hooks/useArticleMenuOptions";

export default function ArticleView({ work, article, children }) {
	const breadcrumbs = useBreadcrumbs({ work, article });
	const options = useArticleMenuOptions({ article });
	const articleTypeTranslations = useArticleTypeTranslations();

	const { input, loading, options: dropdownMenuOptions } = useArticleDropdownMenuOptions({ article });

	return (
		<PageView
			breadcrumbs={breadcrumbs}
			image={<CoverImage modal thumb rounded size={80} data={article} loading={loading} />}
			header={article.name}
			subheader={`${article.publisher.name} - ${articleTypeTranslations[article.type]} (${article.isbn})`}
			sidebar={<VerticalMenu options={options} />}
			extra={
				<>
					<ArticleDeliveryStatus article={article} />

					<AdminPageDropdownMenu options={dropdownMenuOptions} />
				</>
			}
			maxWidth={1600}
		>
			{children}

			{input}
		</PageView>
	);
}
