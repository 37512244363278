import React from "react";
import { useTranslation } from "react-i18next";

import { formatDate } from "../../../../../helpers/date";
import Form from "../../../../forms/components/Form/Form";

export default function ArticleDistributionDateForm({ channel }) {
	const { t } = useTranslation();
	const supportsMetadata =
		!channel.acceptedDeliveryTypes?.length || channel.acceptedDeliveryTypes.includes("metadata");
	return (
		<Form.Group widths="equal">
			{supportsMetadata && (
				<Form.DateInput
					name="announcementDate"
					label={t("announcementDate", "Announcement date")}
					formatDate={formatDate}
					rules={{ valueAsDate: false }}
					shouldUnregister={false}
				/>
			)}
			<Form.DateInput
				name="releaseDate"
				label={t("releaseDate", "Release date")}
				formatDate={formatDate}
				rules={{ valueAsDate: false }}
				shouldUnregister={false}
			/>
			{supportsMetadata && (
				<Form.DateInput
					name="takedownDate"
					label={t("takedownDate", "Takedown date")}
					formatDate={formatDate}
					rules={{ valueAsDate: false }}
					shouldUnregister={false}
				/>
			)}
		</Form.Group>
	);
}
