import firebase from "firebase/app";

import { useTranslation } from "react-i18next";

import { DistributionChannel } from "astrid-firestore/src/api/distribution/types/DistributionChannel";
import updateDistributionChannel from "astrid-firestore/src/api/distribution/updateDistributionChannel";

import AdminForm from "../../../../admin/components/AdminForm/AdminForm";
import ArticleTypeSelect from "../../../../articles/components/ArticleTypeSelect/ArticleTypeSelect";
import { useProfile } from "../../../../authentication/state/profile";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

export default function SuperAdminDistributionChannelForm({ channel }) {
	const { t } = useTranslation();

	const form = useSimpleForm({
		schema: DistributionChannel,
		defaultValues: {
			ref: channel.ref,
			name: channel.name || "",
			active: channel.active || false,
			disableArtifactDeliveryWithoutMetadata: channel.disableArtifactDeliveryWithoutMetadata || false,
			acceptedLanguages: channel.acceptedLanguages || [],
			acceptedArticleTypes: channel.acceptedArticleTypes || [],
			acceptedDeliveryTypes: channel.acceptedDeliveryTypes || [],
			distributionPipelineId: channel.distributionPipelineId || null,
			maxConcurrentDistributions: channel.maxConcurrentDistributions,
			maxFileDurationInMinutes: channel.maxFileDurationInMinutes || null,
			supportsAnnouncementDate: channel.supportsAnnouncementDate || false,
			supportsTakedownDate: channel.supportsTakedownDate || false,
			releaseDateGracePeriod: channel.releaseDateGracePeriod || 0,
			takedownDateGracePeriod: channel.takeDownDateGracePeriod || 0,
			updatedBy: useProfile(),
		},
		onSubmit: (data) => {
			return updateDistributionChannel(firebase, data);
		},
	});

	return (
		<AdminForm header={t("settings", "Settings")} {...form}>
			<Form.Group widths="equal">
				<Form.Input name="name" label={t("name", "Name")} />

				<Form.Select
					name="distributionPipelineId"
					label={t("distributionPipeline", "Distribution pipeline")}
					options={Object.values(channel.pipelines || {})?.map((pipeline) => ({
						key: pipeline.id,
						value: pipeline.id,
						text: pipeline.name,
					}))}
					allowEmpty
				/>
			</Form.Group>

			<Form.Group widths="equal">
				<Form.Select
					name="acceptedDeliveryTypes"
					label={t("acceptedDeliveryTypes", "Accepted delivery types")}
					placeholder={t("allDeliveryTypes", "All delivery types")}
					options={[
						{ key: "artifact", value: "artifact", text: t("artifact", "Artifact") },
						{ key: "metadata", value: "metadata", text: t("metadata", "Metadata") },
						{ key: "cover", value: "cover", text: t("cover", "Cover") },
					]}
					multiple
				/>

				<Form.Field
					name="acceptedArticleTypes"
					label={t("acceptedArticles", "Accepted articles")}
					placeholder={t("allArticles", "All articles")}
					multiple
				>
					<ArticleTypeSelect />
				</Form.Field>
			</Form.Group>

			<Form.Group widths="equal">
				<Form.LanguageSelect
					name="acceptedLanguages"
					label={t("acceptedLanguages", "Accepted languages")}
					placeholder={t("allLanguages", "All languages")}
					multiple
				/>
				<Form.NumberInput
					name="maxConcurrentDistributions"
					label={t("maxConcurrentDistributions", "Max concurrent distributions")}
				/>
				<Form.NumberInput
					name="maxFileDurationInMinutes"
					placeholder={t("unlimited", "Unlimited")}
					label={t("maxFileDurationInMinutes", "Max file duration in minutes")}
				/>
			</Form.Group>
			<Form.Group widths="equal">
				<Form.NumberInput
					name="releaseDateGracePeriod"
					placeholder="0"
					label={t("releaseDateGracePeriod", "Release date grace period (in days)")}
				/>
				<Form.NumberInput
					name="takedownDateGracePeriod"
					placeholder="0"
					label={t("takedownDateGracePeriod", "Takedown date grace period (in days)")}
				/>
			</Form.Group>

			<Form.Checkbox
				slider
				name="disableArtifactDeliveryWithoutMetadata"
				label={t("disableArtifactDeliveryWithoutMetadata", "Prevent delivery of artifact without metadata")}
				labelFalse={t("enableArtifactDeliveryWithoutMetadata", "Artifact can be delivered without metadata")}
			/>
			<Form.Checkbox
				slider
				name="supportsAnnouncementDate"
				label={t("supportsAnnouncementDate", "Supports announcement date")}
				labelFalse={t("doesNotSupportAnnouncementDate", "Does not support announcement date")}
			/>
			<Form.Checkbox
				slider
				name="supportsTakedownDate"
				label={t("supportsTakedownDate", "Supports takedown date")}
				labelFalse={t("doesNotSupportTakedownDate", "Does not support takedown date")}
			/>
			<Form.Checkbox slider name="active" label={t("active", "Active")} labelFalse={t("inactive", "Inactive")} />
		</AdminForm>
	);
}
