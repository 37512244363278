import getCollectionData from "../../utils/getCollectionData";
import runTransaction from "../../utils/runTransaction";

import articleDeliveryStatuses from "../articles/constants/articleDeliveryStatuses";
import updateArticle from "../articles/updateArticle";

import distributionStatusToArticleStatus from "./constants/distributionStatusToArticleStatus";
import distributionStatuses from "./constants/distributionStatuses";
import { DistributionRef } from "./types/DistributionRef";
import { channelSupportsArticle } from "./utils/deliverArticlesToChannels";

export default async function deleteDistribution(firebase, { article, distribution }) {
	const db = firebase.firestore();

	const previousDistributions = await getCollectionData(
		db
			.collection("distributions")
			.where("article.ref", "==", article.ref)
			.where("channel.id", "==", distribution.channel.id)
			.orderBy("created", "desc"),
	);

	const scheduledDistributions = previousDistributions
		.filter((dist) => dist.id !== distribution.id)
		.filter((distribution) => distribution.status === distributionStatuses.SCHEDULED);

	await runTransaction(
		firebase,
		[distribution.ref, distribution.channel.ref],
		async (transaction, [distribution, channel]) => {
			if (
				[distributionStatuses.COMPLETED, distributionStatuses.SCHEDULED, distributionStatuses.FAILED].includes(
					distribution.status,
				)
			) {
				const articleChannelData = Object.entries(distribution.delivery).reduce(
					(acc, [deliveryType, enabled]) => {
						const hasScheduledDistributionForType = scheduledDistributions.some(
							(distribution) => distribution.delivery[deliveryType],
						);
						if (enabled) {
							const latestNonScheduledDistribution = previousDistributions.find(
								(dist) =>
									dist.id !== distribution.id &&
									dist.status !== distributionStatuses.SCHEDULED &&
									dist.delivery[deliveryType],
							);

							const status = hasScheduledDistributionForType
								? articleDeliveryStatuses.DELIVERY_PENDING
								: distributionStatusToArticleStatus[latestNonScheduledDistribution?.status] ||
								  (channelSupportsArticle({ article, channel, deliveryType })
										? articleDeliveryStatuses.DELIVERY_NEEDED
										: articleDeliveryStatuses.DELIVERY_UNSUPPORTED);

							return {
								...acc,
								[`channels.${distribution.channel.id}.delivery.${deliveryType}.status`]: status,
								[`channels.${distribution.channel.id}.delivery.${deliveryType}.distribution`]:
									latestNonScheduledDistribution
										? DistributionRef.parse(latestNonScheduledDistribution)
										: null,
							};
						}
						return acc;
					},
					{},
				);

				await updateArticle(firebase, { ref: article.ref, ...articleChannelData }, { transaction });

				transaction.delete(
					db.collection("distributions").doc(distribution.id).collection("progress").doc("progressDoc"),
				);
				transaction.delete(distribution.ref);
			}
		},
	);
}
