import firebase from "firebase";

import React from "react";
import { useTranslation } from "react-i18next";

import { ArticleChannelMetaData } from "astrid-firestore/src/api/articles/types/ArticleChannel";
import updateArticle from "astrid-firestore/src/api/articles/updateArticle";

import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

import ArticleDistributionDateForm from "./ArticleDistributionDateForm";
import ArticleDistributionTargetDataForm from "./ArticleDistributionTargetDataForm";

export default function ArticleDistributionChannelForm({ channel, article, publisher, onClose }) {
	const { t } = useTranslation();

	const channelSettings = article.channels?.[channel.id];

	const { form, onSubmit, error } = useSimpleForm({
		schema: ArticleChannelMetaData.refine(
			({ releaseDate }) => {
				return releaseDate;
			},
			{
				message: "Release date is mandatory",
				path: ["releaseDate"],
			},
		)
			.refine(
				({ announcementDate, releaseDate }) => {
					return !announcementDate || !releaseDate || announcementDate < releaseDate;
				},
				{
					message: "Must be after announcement date",
					path: ["releaseDate"],
				},
			)
			.refine(
				({ releaseDate, takedownDate }) => {
					return !releaseDate || !takedownDate || releaseDate <= takedownDate;
				},
				{
					message: "Must be after release date",
					path: ["takedownDate"],
				},
			),
		defaultValues: {
			ref: article.ref,
			announcementDate: channelSettings?.announcementDate || null,
			releaseDate: channelSettings?.releaseDate || null,
			takedownDate: channelSettings?.takedownDate || null,
			prices: channelSettings?.prices || [],
			countries: channelSettings?.countries || ["WORLD"],
			subChannels: channelSettings?.subChannels || [],
		},
		onSubmit: ({ ref, announcementDate, releaseDate, takedownDate, prices, countries, subChannels }) => {
			return updateArticle(firebase, {
				ref,
				channels: {
					[channel.id]: {
						announcementDate,
						releaseDate,
						takedownDate,
						prices,
						countries,
						subChannels,
					},
				},
			});
		},
		onSuccess: onClose,
	});

	return (
		<Form.Modal form={form} header={channel.name} onSubmit={onSubmit} onClose={onClose} error={error}>
			<ArticleDistributionDateForm channel={channel} />
			<ArticleDistributionTargetDataForm channel={channel} publisher={publisher} />
		</Form.Modal>
	);
}
