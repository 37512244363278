import { Accordion, Icon } from "semantic-ui-react";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AdminCard from "../../../../admin/components/AdminCard/AdminCard";
import useHasRole from "../../../../authorization/hooks/useHasRole";
import ArticleDistributionsTable from "../../../../distribution/components/DistributionsCollectionDataTable/ArticleDistributionsTable";

export default function SuperAdminArticleDistribution({ article }) {
	const { t } = useTranslation();
	const hasRole = useHasRole();

	const [active, setActive] = useState(false);

	return (
		hasRole("owner", "admin") && (
			<Accordion style={{ marginTop: 20 }}>
				<Accordion.Title active={active} onClick={() => setActive(!active)}>
					<Icon name={active ? "chevron down" : "chevron right"} />
					{t("advanced", "Advanced")}
				</Accordion.Title>
				<Accordion.Content active={active}>
					<AdminCard transparent>
						<ArticleDistributionsTable article={article} />
					</AdminCard>
				</Accordion.Content>
			</Accordion>
		)
	);
}
