import articleDeliveryStatuses from "astrid-firestore/src/api/articles/constants/articleDeliveryStatuses";
import { useDocumentData } from "astrid-firestore/src/hooks";

import { getPipelineProgressPercentage } from "../../pipelines/utils/pipelineProgress";

import useArticleDeliveryStatuses from "./useArticleDeliveryStatuses";

export default function useArticleDeliveryStatus(deliveryType, { article, channel }) {
	const statuses = useArticleDeliveryStatuses();

	const { delivery } = article.channels?.[channel?.id] || article;

	const { status = articleDeliveryStatuses.DELIVERY_NOT_STARTED, distribution } = delivery?.[deliveryType] || {};

	const subscribe = !!distribution?.ref && status === articleDeliveryStatuses.DELIVERY_IN_PROGRESS;

	const [data] = useDocumentData(subscribe && distribution?.ref.collection("progress").doc("progressDoc"));

	const percent = getPipelineProgressPercentage(data?.progress);

	const sent =
		status === articleDeliveryStatuses.DELIVERY_COMPLETED &&
		delivery?.[deliveryType]?.sent?.toDate?.()?.toLocaleString();

	return { percent, sent, ...statuses[status] };
}
