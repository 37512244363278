import firebase from "firebase/app";

import React from "react";
import { useNavigate } from "react-router-dom";

import getArticlesQuery from "astrid-firestore/src/api/articles/getArticlesQuery";
import { useCollectionData } from "astrid-firestore/src/hooks/index";

import Flex from "../../../../components/Flex/Flex";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import hardCodedFeatureFlags from "../../../authorization/constants/hardCodedFeatureFlags";
import Table from "../../../tables/components/Table";
import SyncWorkMetaDataButton from "../../../works/components/SyncWorkMetadataForm/SyncWorkMetaDataButton";

import useArticlesTableColumns from "../../hooks/useArticlesTableColumns";

import AddArticleButton from "../AddArticleForm/AddArticleButton";
import AdvancedDistributeArticlesButton from "../AdvancedDistributeArticles/AdvancedDistributeArticlesButton";

export default function ArticlesCollectionDataTable({ publisher, imprint, serie, work, ...props }) {
	const navigate = useNavigate();

	const columns = useArticlesTableColumns();

	const distributeMultipleArticles = hardCodedFeatureFlags.distributeMultipleArticles;

	const query = getArticlesQuery(firebase, {
		publisher,
		imprint,
		serie,
		work,
	});

	const [articles, loading, error] = useCollectionData(query);

	return (
		<LoadingContext data={articles} loading={loading} error={error}>
			<Table data={articles} columns={columns} onClick={({ id }) => navigate(id)} enableCheckboxes {...props}>
				{({ setSelectedRowIds, selectedRows }) => (
					<Flex gap={10} justifyContent="flex-start">
						{work && <AddArticleButton primary work={work} />}

						{work && (
							<SyncWorkMetaDataButton
								work={work}
								articles={selectedRows}
								onSuccess={() => setSelectedRowIds([])}
							/>
						)}

						{distributeMultipleArticles && selectedRows && (
							<AdvancedDistributeArticlesButton
								articles={selectedRows}
								onSuccess={() => setSelectedRowIds([])}
							/>
						)}
					</Flex>
				)}
			</Table>
		</LoadingContext>
	);
}
