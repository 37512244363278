import firebase from "firebase/app";

import { useTranslation } from "react-i18next";

import distributionStatuses from "astrid-firestore/src/api/distribution/constants/distributionStatuses";
import deleteDistribution from "astrid-firestore/src/api/distribution/deleteDistribution";

import { getOrganizationPath } from "../../routing/utils/getOrganizationPath";

const retryPipeline = firebase.functions().httpsCallable("distribution-retryPipeline");

export default function useDistributionDropdownMenuOptions({ article }) {
	const { t } = useTranslation();

	return (distribution) => [
		{
			text: t("view", "View"),
			linkTo: getOrganizationPath(`/distributions/${distribution.id}`),
		},
		{
			text: t("retry", "Retry"),
			hidden: !distribution?.jobId || distribution?.status !== distributionStatuses.FAILED,
			action: () => {
				return retryPipeline({ collectionId: "distributions", documentId: distribution.id });
			},
		},
		{
			text: t("delete", "Delete"),
			hidden: ![
				distributionStatuses.COMPLETED,
				distributionStatuses.SCHEDULED,
				distributionStatuses.FAILED,
			].includes(distribution.status),
			action: () => {
				return deleteDistribution(firebase, { article, distribution });
			},
		},
	];
}
