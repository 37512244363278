import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

import Flex from "../../../../components/Flex/Flex";
import AsyncButton from "../../../ui/components/AsyncButton/AsyncButton";
import Button from "../../../ui/components/Buttons/Button";
import ConfirmationButton from "../../../ui/components/ConfirmationButton/ConfirmationButton";
import Toast from "../../../ui/components/Toast/Toast";

import DeliverArticleModal from "../../../articles/components/DistributeArticles/DeliverArticleModal";
import Form from "../../../forms/components/Form/Form";
import Table from "../../../tables/components/Table";

import ArtifactAudioPlayer from "../../components/ArtifactAudioPlayer/ArtifactAudioPlayer";
import useArtifactFilesTableColumns from "../../hooks/useArtifactFilesTableColumns";

import ArtifactEpubReader from "../ArtifactEpubReader/ArtifactEpubReader";
import ArtifactFilesUploadForm from "../ArtifactFilesUpload/ArtifactFilesUploadForm";

import useArtifactManagerFiles from "./hooks/useArtifactManagerFiles";
import useArtifactManagerForm from "./hooks/useArtifactManagerForm";
import useArtifactManagerMarkers from "./hooks/useArtifactManagerMarkers";

export default function ArtifactManager({ artifact }) {
	const { t } = useTranslation();

	const { article, publisher } = useOutletContext();

	const [showUploadModal, setShowUploadModal] = useState(false);

	const [files] = useCollectionData(artifact.ref.collection("files"));

	const { form, formState, onSubmit, onReset, deliveryModal } = useArtifactManagerForm({
		article,
		artifact,
		files,
	});

	const {
		sortedFiles,
		isFilesSorted,
		contentTypes,
		onAddFiles,
		onSortFiles,
		onReorderFiles,
		onDownloadFiles,
		onDeleteFiles,
	} = useArtifactManagerFiles({
		form,
		artifact,
		files,
	});

	const { markers, onAddMarker, onUpdateMarker, onRemoveMarker } = useArtifactManagerMarkers({
		form,
		artifact,
		files: sortedFiles,
	});

	const columns = useArtifactFilesTableColumns({ contentTypes, onReorderFiles });

	return (
		<Form form={form}>
			{contentTypes.includes("audio/wav") && (
				<ArtifactAudioPlayer
					files={sortedFiles}
					markers={markers}
					onAddMarker={onAddMarker}
					onUpdateMarker={onUpdateMarker}
					onRemoveMarker={onRemoveMarker}
				/>
			)}

			{contentTypes.includes("application/epub+zip") && (
				<ArtifactEpubReader
					files={sortedFiles}
					markers={markers}
					onAddMarker={onAddMarker}
					onUpdateMarker={onUpdateMarker}
					onRemoveMarker={onRemoveMarker}
				/>
			)}

			<Table columns={columns} data={sortedFiles} enableCheckboxes>
				{({ selectedRows }) => (
					<Flex justifyContent="flex-start" gap={10}>
						<AsyncButton onClick={() => setShowUploadModal(true)} content={t("add", "Add")} />

						<Button onClick={onSortFiles} disabled={isFilesSorted}>
							{t("sort", "Sort")}
						</Button>

						<Button onClick={() => onDownloadFiles(selectedRows)} disabled={selectedRows.length === 0}>
							{t("download", "Download")}
						</Button>

						<ConfirmationButton
							header={t("delete", "Delete")}
							text={selectedRows?.map((artifact) => artifact.name).join("\n")}
							negative={selectedRows.length > 0}
							disabled={selectedRows.length === 0}
							onConfirm={() => onDeleteFiles(selectedRows)}
						>
							{t("delete", "Delete")}
						</ConfirmationButton>
					</Flex>
				)}
			</Table>

			<Toast open={formState.isDirty}>
				<Button onClick={onReset} disabled={!formState.isDirty || formState.isSubmitting}>
					{t("reset", "Reset")}
				</Button>

				<Button primary onClick={onSubmit} disabled={!formState.isDirty || formState.isSubmitting}>
					{t("saveChanges", "Save changes")}
				</Button>
			</Toast>

			{showUploadModal && (
				<ArtifactFilesUploadForm
					auto
					bucketName="earselect-artifacts"
					bucketPath={artifact.id}
					onSuccess={onAddFiles}
					onClose={() => setShowUploadModal(false)}
				/>
			)}
			{deliveryModal?.show && (
				<DeliverArticleModal article={article} publisher={publisher} onClose={deliveryModal.onClose} />
			)}

			<Form.Blocker />
		</Form>
	);
}
