import firebase from "firebase/app";

import { List, ListHeader, ListItem, Message, Modal } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import artifactValidationStatuses from "astrid-firestore/src/api/artifacts/constants/artifactValidationStatuses";
import distributeArticles from "astrid-firestore/src/api/distribution/distributeArticles";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";
import stopPropagation from "astrid-helpers/src/stopPropagation";

import Flex from "../../../../components/Flex/Flex";
import PrimaryButton from "../../../ui/components/Buttons/PrimaryButton";
import SecondaryButton from "../../../ui/components/Buttons/SecondaryButton";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";
import useLoadingFunction from "../../../ui/hooks/useLoadingFunction";

import ArtifactValidationStatusLabel from "../../../artifacts/components/ArtifactStatus/ArtifactValidationStatusLabel";

import useArticlesChannels from "../../hooks/useArticlesChannels";

import useAutoDeliveries from "./hooks/useAutoDeliveries";

export default function DeliverArticleModal({ publisher, article, onClose }) {
	const { t } = useTranslation();

	const [channels, loadingChannels] = useArticlesChannels({ articles: [article] });
	const [artifact, loadingArtifact] = useDocumentData(article?.artifact?.ref);

	const [distributeArticle, loading, error] = useLoadingFunction(
		() => {
			return distributeArticles(firebase, { articles: [article], channels });
		},
		{ onSuccess: onClose },
	);

	const [deliveries, errorDeliveries] = useAutoDeliveries({ article, publisher, channels });

	const hasDeliveries = deliveries?.length;
	const artifactIsNotValid = artifact && artifact?.status !== artifactValidationStatuses.VALID;

	return (
		<Modal size="tiny" open padded onClose={onClose} onKeyDown={stopPropagation} onClick={stopPropagation}>
			<Modal.Content>
				<LoadingContext data={channels} loading={loadingChannels} error={error || errorDeliveries}>
					{hasDeliveries ? (
						<>
							<p>
								{`${t(
									"confirmDelivery",
									"Would you like to queue/schedule a delivery for the following channels",
								)}:`}
							</p>

							<List>
								{deliveries?.map((channel) => {
									return (
										<ListItem key={channel.id}>
											<ListHeader>{channel.name}</ListHeader>
										</ListItem>
									);
								})}
							</List>
							{artifact && artifactIsNotValid && (
								<Message
									info
									content={
										<Flex>
											<i>
												{`${t(
													"currentlyValidatingArtifact",
													"The artifact is currently validating. Delivery will be possible once validation is completed and the artifact is valid.",
												)}...`}
											</i>
											<div>
												<ArtifactValidationStatusLabel artifact={artifact} />
											</div>
										</Flex>
									}
								/>
							)}
						</>
					) : (
						<i>{t("noDeliveryNeeded", "There is currently nothing to deliver")}</i>
					)}
				</LoadingContext>
			</Modal.Content>
			<Modal.Actions>
				<SecondaryButton content={t("cancel", "Cancel")} onClick={onClose} />
				<PrimaryButton
					disabled={loadingChannels || !hasDeliveries || errorDeliveries || artifactIsNotValid}
					onClick={distributeArticle}
					loading={loading || loadingArtifact}
				>
					{t("deliver", "Deliver")}
				</PrimaryButton>
			</Modal.Actions>
		</Modal>
	);
}
