import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import DeliverArticleModal from "./DeliverArticleModal";

export default function DeliverArticleButton({ article, publisher }) {
	const { t } = useTranslation();
	return (
		<OpenButton primary text={t("deliver", "Deliver")}>
			{({ setOpen }) => {
				return <DeliverArticleModal publisher={publisher} article={article} onClose={() => setOpen(false)} />;
			}}
		</OpenButton>
	);
}
