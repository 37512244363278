import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Flex from "../../../../components/Flex/Flex";
import InstantSearch from "../../../ui/components/InstantSearch/InstantSearch";
import InstantSearchBox from "../../../ui/components/InstantSearch/InstantSearchBox";
import InstantSearchClear from "../../../ui/components/InstantSearch/InstantSearchClear";
import InstantSearchHits from "../../../ui/components/InstantSearch/InstantSearchHits";
import InstantSearchHitsPerPage from "../../../ui/components/InstantSearch/InstantSearchHitsPerPage";
import InstantSearchPagination from "../../../ui/components/InstantSearch/InstantSearchPagination";
import InstantSearchPresets from "../../../ui/components/InstantSearch/InstantSearchPresets";
import InstantSearchRefinements from "../../../ui/components/InstantSearch/InstantSearchRefinements";
import InstantSearchRefresh from "../../../ui/components/InstantSearch/InstantSearchRefresh";
import Toast from "../../../ui/components/Toast/Toast";
import PageView from "../../../ui/views/PageView/PageView";

import hardCodedFeatureFlags from "../../../authorization/constants/hardCodedFeatureFlags";
import Table from "../../../tables/components/Table";

import AdvancedDistributeArticlesButton from "../../components/AdvancedDistributeArticles/AdvancedDistributeArticlesButton";
import CreateArticleButton from "../../components/CreateArticleForm/CreateArticleButton";
import useArticleDropdownMenuOptions from "../../hooks/useArticleDropdownMenuOptions";
import useArticlesRefinements from "../../hooks/useArticlesRefinements";
import useArticlesTableColumns from "../../hooks/useArticlesTableColumns";

export default function ArticlesView({ organization }) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const columns = useArticlesTableColumns({ organization });
	const refinements = useArticlesRefinements({ organization });

	const getDropdownMenuOptions = useArticleDropdownMenuOptions();

	const presetsPath = `articles-${organization?.id || "admin"}`;

	const configure = {
		filters: organization && `${organization.type}.id:${organization.id}`,
	};

	return (
		<InstantSearch routing indexName="articles" configure={configure}>
			<InstantSearchRefresh refreshInterval={5000} />

			<PageView
				header={t("articles", "Articles")}
				extra={<CreateArticleButton publisher={organization} />}
				sidebar={
					<>
						<InstantSearchBox />
						<InstantSearchRefinements refinements={refinements} />
					</>
				}
				maxWidth={1600}
			>
				<Flex gap={10} justifyContent="flex-start">
					<Flex gap={10} justifyContent="flex-start">
						<InstantSearchPresets path={presetsPath} refinements={refinements} />
						<InstantSearchClear />
					</Flex>
					<InstantSearchHitsPerPage />
				</Flex>

				<InstantSearchHits>
					<Table
						columns={columns}
						enableCheckboxes
						enableColumnSelector
						getDropdownMenuOptions={getDropdownMenuOptions}
						onClick={({ id }) => navigate(id)}
					>
						{({ selectedRows, setSelectedRowIds }) => (
							<Toast open={selectedRows.length > 0 && hardCodedFeatureFlags.distributeMultipleArticles}>
								<AdvancedDistributeArticlesButton
									primary
									articles={selectedRows}
									onSuccess={() => setSelectedRowIds([])}
								/>
							</Toast>
						)}
					</Table>
				</InstantSearchHits>

				<InstantSearchPagination />
			</PageView>
		</InstantSearch>
	);
}
