import firebase from "firebase/app";

import { useTranslation } from "react-i18next";

import articleStatuses from "astrid-firestore/src/api/articles/constants/articleStatuses";
import updateArticle from "astrid-firestore/src/api/articles/updateArticle";
import softDeleteDocument from "astrid-firestore/src/api/documents/softDeleteDocument";

import { useProfile } from "../../../../authentication/state/profile";
import { getOrganizationPath } from "../../../../routing/utils/getOrganizationPath";
import useFileUpload from "../../../../ui/hooks/useFileUpload";

export default function useArticleDropdownMenuOptions({ article }) {
	const user = useProfile();

	const { t } = useTranslation();

	const [{ uploadFile, input, error, loading }] = useFileUpload({
		bucketName: "earselect-uploads",
		getDestination: () => `covers/${article.id}-${Date.now()}`,
		onSuccess: (cover) => {
			return updateArticle(firebase, { ref: article.ref, cover });
		},
	});

	const options = [
		{
			text: t("viewWork", "View work"),
			disabled: !article.work?.id,
			linkTo: getOrganizationPath(`/works/${article.work?.id}`),
		},
		{
			text: t("uploadCover", "Upload cover"),
			onClick: uploadFile,
		},
		{
			text: t("setAsDraft", "Set to draft"),
			hidden: article.status === "draft",
			confirmText: t("sureSetDraft", "Are you sure you wish to set this article to draft?"),
			action: () => {
				return updateArticle(firebase, { ref: article.ref, status: articleStatuses.DRAFT });
			},
		},
		{
			text: t("delete", "Delete"),
			confirmText: t("sureDeleteArticle", "Are you sure you wish to delete this article?"),
			action: () => {
				return softDeleteDocument(firebase, article, user);
			},
		},
	];

	return { options, input, loading, error };
}
