import getCollectionData from "../../utils/getCollectionData";
import getDocumentData from "../../utils/getDocumentData";

import { canDeliverArticleToChannel, channelSupportsArticle } from "../distribution/utils/deliverArticlesToChannels";
import getPublisherDistributionPresets from "../distribution/utils/getPublisherDistributionPresets";

import articleDeliveryStatuses from "./constants/articleDeliveryStatuses";

function getEligibleDistributionChannels({ channels, article }) {
	return channels.filter(
		({ active, acceptedLanguages, acceptedArticleTypes }) =>
			active &&
			(acceptedArticleTypes?.length === 0 || acceptedArticleTypes?.includes(article.type)) &&
			(acceptedLanguages?.length === 0 || acceptedLanguages?.includes(article.language)),
	);
}

export default async function applyDistributionPresets(firebase, article) {
	const [publisher, channels] = await Promise.all([
		getDocumentData(article.publisher.ref),
		getCollectionData(firebase.firestore().collection("distributionChannels").where("deleted", "==", false)),
	]);

	const eligibleDistributionChannels = getEligibleDistributionChannels({ channels, article });
	const presets = getPublisherDistributionPresets({ article, publisher });

	const channelPresets = eligibleDistributionChannels.reduce((acc, channel) => {
		const preset = presets.find((preset) => preset.channel.id === channel.id);
		if (preset) {
			return {
				...acc,
				[channel.id]: {
					delivery: {
						metadata: {
							status: channelSupportsArticle({
								article,
								channel,
								deliveryType: "metadata",
							})
								? articleDeliveryStatuses.DELIVERY_NEEDED
								: articleDeliveryStatuses.DELIVERY_UNSUPPORTED,
						},
						artifact: {
							status: channelSupportsArticle({
								article,
								channel,
								deliveryType: "artifact",
							})
								? articleDeliveryStatuses.DELIVERY_NEEDED
								: articleDeliveryStatuses.DELIVERY_UNSUPPORTED,
						},
					},
					...(preset?.targetData || {}),
				},
			};
		}
		return acc;
	}, {});

	return {
		channels: channelPresets,
		channelIds: Object.keys(channelPresets),
	};
}
