import equal from "fast-deep-equal";

import { ArticleMetaData } from "../types/ArticleMetaData";

export default function getUpdatedArticleDeliveryTypes(article, data) {
	const deliveryTypes = [];

	const metaDataBefore = ArticleMetaData.partial().parse(article);
	const metaDataAfter = data ? ArticleMetaData.partial().parse(data) : {};

	const updatedKeys = Object.keys(metaDataAfter).filter((key) => !equal(metaDataBefore[key], metaDataAfter[key]));

	if (updatedKeys.length > 0) {
		if (updatedKeys.length === 1 && updatedKeys[0] === "channels") {
			// This check is need since the channels object becomes empty when only triggering a file delivery (since the schema is built that way)
			const onlyTriggeredFileDelivery = Object.values(metaDataAfter.channels).every((channel) => {
				return Object.keys(channel).length === 0;
			});

			if (!onlyTriggeredFileDelivery) {
				deliveryTypes.push("metadata");
			}
		} else {
			deliveryTypes.push("metadata");
		}
	}

	if (data.channels) {
		const channelId = Object.keys(data.channels)[0];
		const releaseDateBefore = metaDataBefore.channels?.[channelId]?.releaseDate;
		const releaseDateAfter = metaDataAfter.channels?.[channelId]?.releaseDate;

		// If release is changed scheduleing needs to be updated and therefore delivery needed should be set.
		if (releaseDateBefore && releaseDateAfter && releaseDateBefore !== releaseDateAfter) {
			deliveryTypes.push("artifact");
		}
	}

	if (data.artifact) {
		deliveryTypes.push("artifact");
	}

	return { deliveryTypes: [...new Set(deliveryTypes)], updatedKeys };
}
